import { Component, } from "@angular/core";
import { NavbarService } from '../nav/navbar.service';
import { EmployeeCodesService } from "../services/EmployeeCodesServices";
@Component({
    templateUrl: './codes-list.component.html'
})
export class CodesListComponent {
    Codes: any[]
    public popoverTitle: string = 'Löschen';
    public popoverMessage: string = 'Diesen Eintrag löschen?';
    public confirmText: string = 'Ja <i class="glyphicon glyphicon-ok"></i>';
    public cancelText: string = 'Nein <i class="glyphicon glyphicon-remove"></i>';
    loadingData: boolean
    constructor(private codesService: EmployeeCodesService, public nav: NavbarService) {
    }
    ngOnInit() {
        this.loadingData = true;
        this.nav.show();
        this.codesService.getCodes().subscribe((data) => {
            this.loadingData = false;
            this.Codes = data;
        });
    }
    deleteCode(id: any) {
        document.getElementById('deleteEmployeeCode_' + id).classList.add("fa-spinner")
        document.getElementById('deleteEmployeeCode_' + id).classList.add("fa-spin")
        this.codesService.deleteCode(id).then((data) => {
            this.ngOnInit();
            document.getElementById('deleteEmployeeCode_' + id).classList.remove("fa-spinner")
            document.getElementById('deleteEmployeeCode_' + id).classList.remove("fa-spin")
        });
    }
}