import { Injectable, OnInit } from '@angular/core';
import { AuthService } from '../services/AuthService';

@Injectable()
export class NavbarService  implements OnInit{
  visible: boolean;
  isAdmin: boolean;
  isEditor: boolean;
  userName: string;
  isShowLogoutButton : boolean;
  constructor(private auth:AuthService) { 
    this.visible = false;
    this.isShowLogoutButton = false;
    this.ngOnInit();
  }
  ngOnInit(){
    this.isAdmin = this.auth.IsAdmin();
    this.isEditor = this.auth.IsEditor();
    this.userName = this.auth.UserName();
}
  hide() { this.visible = false; }

  show() { this.visible = true;
  this.isShowLogoutButton = true; }

  toggle() { this.visible = !this.visible; }

}