import { Component, OnInit } from "@angular/core";
import { UserService } from '../services/UserService';
import { NavbarService } from '../nav/navbar.service';
@Component({
    templateUrl: './create-admin.component.html'
})
export class CreateAdminComponent implements OnInit {
    constructor(private userService: UserService, public nav: NavbarService) { }
    ngOnInit() {
        this.nav.show();
    }
    createAdmin(e) {
        e.preventDefault();
        this.userService.createAdmin(e);

    }
}