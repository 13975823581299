
export class AuthService{
constructor(){}
ngOnInit(){
    this.IsAuthenticated();
    this.IsAdmin();
    this.IsEditor();
    this.UserName();
}
IsAuthenticated(){
        var userToken = JSON.parse(localStorage.getItem("xauth"));
        if(userToken == null){
            return false;
        }
        return true;
    }
    IsAdmin(){
        var userToken = JSON.parse(localStorage.getItem("xauth"));
        if(userToken == null || userToken.type !== "admin"){
            return false;
        }
        return true;
    }
    IsEditor(){
        var userToken = JSON.parse(localStorage.getItem("xauth"));
        if(userToken == null || userToken.type !== "editor"){
            return false;
        }
        return true;
    }
    UserName(){
        var userToken = JSON.parse(localStorage.getItem("xauth"));
        if(userToken == null){
            return false;
        }
        return userToken.name;
    }
}