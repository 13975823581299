import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component';
import { UsersListComponent } from './users/users-list.component';
import { ArticlesListComponent } from './articles/articles-list.component';
import { NarBarComponent } from './nav/nav-bar.component';

import { UserService } from './services/UserService';
import { LoginService } from './services/loginservice';
import { ToastrService } from './common/toastr.service';
import { NavbarService } from './nav/navbar.service';
import { DepartmentService } from './services/DepartmentServices';
import { EmployeeCodesService } from './services/EmployeeCodesServices';
import { CategoryService } from './services/CategoryServices';
import { NotificationService } from './services/NotificationService';
import { ArticleService } from './services/ArticleService';


import { RouterModule } from '@angular/router';
import { Error404Component } from './errors/404.component';
import { LoginComponent } from './Auth/login.component';
import { HttpModule } from '@angular/http';
import { appRouts } from './app-routing.module';
import { AddUserComponent } from './users/add-user.component';
import { CreateEditorComponent } from './users/create-editor.component';
import { CreateAdminComponent } from './users/create-admin.component';
import { CreateArticleComponent } from './articles/create-article.component';
import { CreateDepartmentComponent } from './department/create-department.component';
import { DepartmentListComponent } from './department/department-list.component';
import { CreateCodeComponent } from './employeecodes/create-code.component';
import { CodesListComponent } from './employeecodes/codes-list.component';
import { CreateCategoryComponent } from './category/create-category.component';
import { CategoryListComponent } from './category/category-list.component';
import { SendNotificationComponent } from './notifications/send-pushnotification.component';

import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTimeFormatPipe } from './common/datetimeformat.component';

import { AuthService } from './services/AuthService';
import { AdminRouteGuardActivator } from './services/admin-route-guard.service';
import { EditorRouteGuardActivator } from './services/editor-route-guard.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditArticleComponent } from './articles/edit-article.component';
import { ArticleDataService } from './services/article-data.service';
import { ToastrModule } from 'ngx-toastr';
import { ArticleFilter } from './articles/article-filter.pipe';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ModalModule } from 'angular-custom-modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { ChangePasswordComponent } from './account/changepassword.component';
import { ForgotPasswordComponent } from './account/forgotpassword.component';
const DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: 'https://httpbin.org',
    maxFilesize: 50,
    acceptedFiles: 'image/*',
    addRemoveLinks: true,
    dictRemoveFile: 'Datei löschen',
    dictCancelUpload: 'Upload abbrechen',
    dictDefaultMessage: 'Dateien hier hin ziehen zum uploaden'
};
@NgModule({
    imports: [
        BrowserModule,
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        Ng2SearchPipeModule,
        OwlDateTimeModule, OwlNativeDateTimeModule, BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        ToastrModule.forRoot(),
        RouterModule.forRoot(appRouts, { useHash: true }),
        DropzoneModule.forRoot(DROPZONE_CONFIG),
        ModalModule,
        NgMultiSelectDropDownModule.forRoot(),
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger'
        })
    ],
    declarations: [
        AppComponent,
        UsersListComponent,
        NarBarComponent,
        CreateArticleComponent,
        EditArticleComponent,
        CreateDepartmentComponent,
        Error404Component,
        LoginComponent,
        AddUserComponent,
        CreateEditorComponent,
        CreateAdminComponent,
        DepartmentListComponent,
        CreateCodeComponent,
        CodesListComponent,
        CreateCategoryComponent,
        CategoryListComponent,
        ArticlesListComponent,
        SendNotificationComponent,
        DateTimeFormatPipe,
        ArticleFilter,
        ChangePasswordComponent,
        ForgotPasswordComponent
    ],
    providers: [
        LoginService,
        NavbarService,
        UserService,
        DepartmentService,
        EmployeeCodesService,
        CategoryService,
        NotificationService,
        ToastrService,
        ArticleService,
        AuthService,
        AdminRouteGuardActivator,
        EditorRouteGuardActivator,
        ArticleDataService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}

function checkDirtyState(component: CreateArticleComponent) {
    // if (component.isDirty)
    //     return window.confirm("Do you want to go back?");
    return false
}