import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http'
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class NotificationService {
    constructor(public toastr: ToastrService, private http: Http, private router: Router) { }
    sendNotification(e, department) {
        var data: any;
        var body: any;
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var title = e.target.elements[0].value;
        var text = e.target.elements[1].value;
        var date = e.target.elements[2].value;
        if (e.target.elements[3].checked) {
            body = {
                "title": title,
                "text": text,
                "department": department,
                "article": 'null',
            };
        } else {
            body = {
                "title": title,
                "text": text,
                "department": department,
                "article": 'null',
                "time": date
            };
        }
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve, reject) => {
            this.http.post("https://tucher-app.de/api/notifications/send", body, options).subscribe(data => {
                if (JSON.parse(data["_body"]).status === 403) {
                    this.toastr.error("Ah Sorry, Your are not authorized to send notifications :(");
                    resolve(false);
                } else if (JSON.parse(data["_body"]).status === 500) {
                    this.toastr.error("Failed Alert, failed to send the notification :(" + JSON.parse(data["_body"]).message);
                    resolve(false);
                } else if (JSON.parse(data["_body"]).status === 200) {
                    this.toastr.success("Notification Sent, You notification on it's way to be delivered...");
                    resolve(true);
                } else if (JSON.parse(data["_body"]).status === 404) {
                    this.toastr.error("Resource Not Found, notification resource not found");
                    resolve(false);
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };

    readKeyFromLocalStorage() {
        return JSON.parse(localStorage.getItem("xauth")).token;
    }
}