import { Routes } from '@angular/router'
import { UsersListComponent } from "./users/users-list.component";
import { CreateArticleComponent } from './articles/create-article.component';
import { Error404Component } from './errors/404.component';
import { LoginComponent } from './Auth/login.component';
import { AddUserComponent } from './users/add-user.component';
import { CreateEditorComponent } from './users/create-editor.component';
import { CreateDepartmentComponent } from './department/create-department.component';
import { DepartmentListComponent } from './department/department-list.component';
import { CreateCategoryComponent } from './category/create-category.component';
import { CategoryListComponent } from './category/category-list.component';
import { SendNotificationComponent } from './notifications/send-pushnotification.component';
import { ArticlesListComponent } from './articles/articles-list.component';
import { AdminRouteGuardActivator } from './services/admin-route-guard.service';
import { EditorRouteGuardActivator } from './services/editor-route-guard.service';
import { CreateCodeComponent } from './employeecodes/create-code.component';
import { CodesListComponent } from './employeecodes/codes-list.component';
import { EditArticleComponent } from './articles/edit-article.component';
import { ChangePasswordComponent } from './account/changepassword.component';
import { ForgotPasswordComponent } from './account/forgotpassword.component';

export const appRouts: Routes = [
     { path: "users/all", component: UsersListComponent, canActivate: [AdminRouteGuardActivator] },
     { path: "user/add", component: AddUserComponent, canActivate: [AdminRouteGuardActivator]  },
     { path: "editor/create", component: CreateEditorComponent, canActivate: [AdminRouteGuardActivator]  },
     { path: "articles/all", component: ArticlesListComponent, canActivate: [EditorRouteGuardActivator]  },
     { path: "article/create", component: CreateArticleComponent, canActivate: [EditorRouteGuardActivator]  },
     { path: "article/edit/:id", component: EditArticleComponent, canActivate: [EditorRouteGuardActivator]  },
     { path: "department/create", component: CreateDepartmentComponent , canActivate: [AdminRouteGuardActivator] },
     { path: "departments/all", component: DepartmentListComponent, canActivate: [AdminRouteGuardActivator]  },
     { path: "notifications/all", component: DepartmentListComponent, canActivate: [AdminRouteGuardActivator]  },
     { path: "employee/codes/create", component: CreateCodeComponent , canActivate: [AdminRouteGuardActivator] },
     { path: "employee/codes/all", component: CodesListComponent, canActivate: [AdminRouteGuardActivator]  },
     { path: "category/create", component: CreateCategoryComponent, canActivate: [EditorRouteGuardActivator]  },
     { path: "categories/all", component: CategoryListComponent, canActivate: [EditorRouteGuardActivator]   },
     { path: "notification/send", component: SendNotificationComponent, canActivate: [EditorRouteGuardActivator]   },
     { path: "changepassword/:token", component: ChangePasswordComponent },
    { path: "forgotpassword", component: ForgotPasswordComponent },
     { path: "login", component:LoginComponent},
     { path:"404", component:Error404Component},
     { path: '', redirectTo: '/login', pathMatch: 'full' }
]