import { Component, OnInit } from "@angular/core";
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService } from '../nav/navbar.service';
import { AuthService } from '../services/AuthService';
import { Http, RequestOptions, Headers } from '@angular/http'
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PasswordValidation } from "../common/custom-validator";
@Component({
    templateUrl: './changepassword.component.html',
})
export class ChangePasswordComponent implements OnInit {
    invalidEmail = false;
    validEmail = false;
    invalidToken = false;
    registerForm: FormGroup;
    submitted = false;
    constructor(private http: Http, private route: ActivatedRoute, public nav: NavbarService, private auth: AuthService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.nav.hide();
        this.registerForm = this.formBuilder.group({
            password: ['', Validators.required],
            confirmpassword: ['', Validators.required]
        },
            {
                validator: PasswordValidation.MatchPassword
            });
    }
    get f() { return this.registerForm.controls; }
    changePassword(e): Observable<any> {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        e.preventDefault();
        var data: any;
        var password = e.target.elements[0].value;
        var authToken = this.route.snapshot.params['token'];
        var body = {
            "password": password
        };
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var options = new RequestOptions({ headers: headers });
        this.http.post("https://tucher-app.de/api/users/change-password", body, options).subscribe(data => {
            if (JSON.parse(data["_body"]).status === 500) {
                this.invalidEmail = true;
            } else if (JSON.parse(data["_body"]).status === 403) {
                this.invalidToken = true;
            } else if (JSON.parse(data["_body"]).status === 200) {
                this.validEmail = true;
            }
        }, error => {
            console.log(JSON.stringify(error.json()));
        });
        return data;
    }
}