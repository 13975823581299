import { Component } from '@angular/core';
import { AuthService } from './services/AuthService';
import { NarBarComponent } from './nav/nav-bar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private auth: AuthService) { }
  title = 'mitarbeiter-admin-panel-angularcli';
  changeOfRoutes() {
    const element = document.getElementById('logoutButton');
    if (window.location.hash === "#/login" || window.location.hash.includes("#/changepassword")) {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }
}
