import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './AuthService';

@Injectable()
export class AdminRouteGuardActivator implements CanActivate{
constructor(private auth: AuthService, private router:Router){

}
canActivate(){
  const isLoggedIn = !!this.auth.IsAuthenticated();
  const isAdmin = !!this.auth.IsAdmin();
  if(!isLoggedIn) {
    this.router.navigate(['/login']);
    }else if(!isAdmin){
      this.router.navigate(['/404']);
    }
    return isLoggedIn;
}
}