import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http'
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class DepartmentService {
    constructor(private toastr: ToastrService, private http: Http, private router: Router) { }
    getDepartments(): Observable<any[]> {
        return this.http.get("https://tucher-app.de/api/departments/all").map((response: Response) => {
            if (response.json().status === 500) {
                this.toastr.error("Ah Sorry, There is some error fetching departments data :(");
            } else if (response.json().status === 200) {
                return <any[]>(response.json().data);
            }
        });
    };
    addDepartment(e) {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var name = e.target.elements[0].value;
        var body = {
            "name": name
        };
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve, reject) => {
            this.http.post("https://tucher-app.de/api/departments/create", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to Add Departments :(");
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Creating department failed :(");
                } else if (data.json().status === 200) {
                    this.router.navigate(['/departments/all'])
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };
    deleteDepartment(id: any) {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var body = {
            "id": id
        };
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve, reject) => {
            this.http.post("https://tucher-app.de/api/departments/delete", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to Delete Departments :(");
                    resolve(false);
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Deleting department failed :(");
                    resolve(false);
                } else if (data.json().status === 200) {
                    resolve(true);
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    }
    readKeyFromLocalStorage() {
        return JSON.parse(localStorage.getItem("xauth")).token;
    }
}