import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http'
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class ArticleService {
    articleContent: string;
    constructor(private toastr: ToastrService, private http: Http, private router: Router) { }
    createArticle(e, articleContent, images, selectedMedia) {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var title = e.target.elements[0].value;
        var categoryId = e.target.elements[1].value;
        var videoLink = '';
        var surveyLink = '';
        if (selectedMedia != null) {
            videoLink = e.target.elements[3].value;
            surveyLink = e.target.elements[4].value;
        } else {
            surveyLink = e.target.elements[3].value;
        }
        var body;
        if (selectedMedia === 'image') {
            body = {
                "title": title,
                "category": categoryId,
                "text": articleContent,
                "images": images,
                "survey": surveyLink
            };
        } else if (selectedMedia === 'video') {
            body = {
                "title": title,
                "category": categoryId,
                "text": articleContent,
                "video": videoLink,
                "survey": surveyLink
            };
        } else {
            body = {
                "title": title,
                "category": categoryId,
                "text": articleContent,
                "survey": surveyLink
            };
        }
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve) => {
            this.http.post("https://tucher-app.de/api/articles/create", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to Add article :(");
                    resolve(false);
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Creating article failed :( " + data.json().message);
                    resolve(false);
                } else if (data.json().status === 200) {
                    this.router.navigate(['/articles/all'])
                    resolve(true);
                } else if (data.json().status === 404) {
                    this.toastr.error("Ah Sorry, Resource not found :(");
                    resolve(false);
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };
    updateArticle(e, article, articleContent, images, selectedMedia) {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var videoLink = '';
        var surveyLink = '';
        if (selectedMedia != null) {
            videoLink = e.target.elements[3].value;
            surveyLink = e.target.elements[4].value;
        } else {
            surveyLink = e.target.elements[3].value;
        }
        var body;
        if (selectedMedia === 'image') {
            body = {
                "id": article.id,
                "title": article.title,
                "category": article.category._id,
                "text": articleContent,
                "images": images,
                "survey": surveyLink
            };
        } else if (selectedMedia === 'video') {
            body = {
                "id": article.id,
                "title": article.title,
                "category": article.category._id,
                "text": articleContent,
                "video": videoLink,
                "survey": surveyLink
            };
        } else {
            body = {
                "id": article.id,
                "title": article.title,
                "category": article.category._id,
                "text": articleContent,
                "survey": surveyLink
            };
        }
        // var body = {
        //     "id": article.id,
        //     "title": article.title,
        //     "category": article.category._id,
        //     "text": article.text,
        //     "survey":article.survey
        // };
        var options = new RequestOptions({ headers: headers });
        return new Promise(() => {
            this.http.post("https://tucher-app.de/api/articles/update", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to update article :(");
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Updating article failed :(" + data.json().message);
                } else if (data.json().status === 200) {
                    this.router.navigate(['/articles/all'])
                } else if (data.json().status === 404) {
                    this.toastr.error("Ah Sorry, Resource not found :(");
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };
    getArticles(): Observable<any[]> {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var body = {
            "page": 1,
            "limit": 100,
        };
        var options = new RequestOptions({ headers: headers });
        return this.http.post("https://tucher-app.de/api/articles/get-all", body, options).map((response: Response) => {
            if (response.json().status === 403) {
                this.toastr.error("Ah Sorry, You are not authorized to View Articles :(");
            } else if (response.json().status === 500) {
                this.toastr.error("Ah Sorry, Failed to load Articles :(" + response.json().message);
            } else if (response.json().status === 404) {
                this.toastr.error("Ah Sorry, Resource not found :(");
            } else if (response.json().status === 200) {
                return <any[]>(response.json().data.docs);
            }
        }, error => {
            console.log(JSON.stringify(error.json()));
        });
    };
    changeArticleStatus(id: string, status: string) {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);

        var body = {
            "id": id,
            "status": status,
        };
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve) => {
            this.http.post("https://tucher-app.de/api/articles/change-status", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to View Articles :(");
                    resolve(false);
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Failed to load Articles :(" + data.json().message);
                    resolve(false);
                } else if (data.json().status === 404) {
                    this.toastr.error("Ah Sorry, Resource not found :(");
                } else if (data.json().status === 200) {
                    resolve(true);
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };
    deleteArticle(id: string) {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var body = {
            "id": id
        };
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve) => {
            this.http.post("https://tucher-app.de/api/articles/delete", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to Delete Articles :(");
                    resolve(false);
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Failed to delete Articles :(" + data.json().message);
                    resolve(false);
                } else if (data.json().status === 404) {
                    this.toastr.error("Ah Sorry, Resource not found :(");
                } else if (data.json().status === 200) {
                    resolve(true);
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };
    readKeyFromLocalStorage() {
        var userToken = JSON.parse(localStorage.getItem("xauth"));
        if (userToken == null) {
            this.router.navigate(['/login']);
        }
        return JSON.parse(localStorage.getItem("xauth")).token;
    }
}