import { Component, OnInit } from "@angular/core";
import { NotificationService } from '../services/NotificationService';
import { DepartmentService } from '../services/DepartmentServices';
import { NavbarService } from '../nav/navbar.service';
import { NgForm } from "@angular/forms";
import { FormBuilder, FormGroup, Validators } from "../../../node_modules/@angular/forms";
@Component({
    templateUrl: './send-pushnotification.component.html'
})
export class SendNotificationComponent implements OnInit {
    sendNotificationForm: FormGroup;
    submitted = false;
    Departments: any[];
    SelectedDepartments: Map<string, string> = new Map<string, string>();
    selectedMoment = new Date();
    title: string;
    text: string;
    sendTo: string;
    dropdownSettings = {};
    constructor(private notificationService: NotificationService, public nav: NavbarService, private departmentService: DepartmentService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.nav.show();
        this.dropdownSettings = {
            idField: '_id',
            textField: 'name',
            singleSelection: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 100,
            allowSearchFilter: true
        };
        this.departmentService.getDepartments().subscribe((data) => {
            this.Departments = data;
        });
        this.sendNotificationForm = this.formBuilder.group({
            title: ['', Validators.required],
            messagetext: ['', Validators.required],
        });
    }
    get f() { return this.sendNotificationForm.controls; }
    sendNotification(e, notificationForm: NgForm) {
        this.submitted = true;
        if (this.sendNotificationForm.invalid) {
            return;
        }
        document.getElementById('sendNotification').classList.add("fa-spinner")
        document.getElementById('sendNotification').classList.add("fa-spin")
        e.preventDefault();
        this.SelectedDepartments.forEach(element => {
            this.notificationService.sendNotification(e, element).then((data) => {
                document.getElementById('sendNotification').classList.remove("fa-spinner")
                document.getElementById('sendNotification').classList.remove("fa-spin")
                notificationForm.reset();
            })
                .catch((error) => {
                    document.getElementById('sendNotification').classList.remove("fa-spinner")
                    document.getElementById('sendNotification').classList.remove("fa-spin")
                });
        })
    }
    onItemSelect(item: any) {
        this.SelectedDepartments.set(item.name, item._id);
    }
    OnItemDeselect(item: any) {
        this.SelectedDepartments.delete(item.name);
    }
    onSelectAll(items: any[]) {
        this.SelectedDepartments.clear();
        items.forEach(element => {
            this.SelectedDepartments.set(element.name, element._id);
        });
    }
}