import { Component, OnInit } from "@angular/core";
import { UserService } from '../services/UserService';
import { NavbarService } from '../nav/navbar.service';
import { ToastrService } from '../common/toastr.service';
import { FormBuilder, FormGroup, Validators } from "../../../node_modules/@angular/forms";
import { PasswordValidation } from "../common/custom-validator";
@Component({
    templateUrl: './create-editor.component.html'
})
export class CreateEditorComponent implements OnInit {
    createEditorForm: FormGroup;
    submitted = false;
    constructor(private userService: UserService, public nav: NavbarService, private toast: ToastrService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.nav.show();
        this.createEditorForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', Validators.required],
            confirmpassword: ['', Validators.required]
        },
            {
                validator: PasswordValidation.MatchPassword
            });
    }
    get f() { return this.createEditorForm.controls; }
    createEditor(e) {
        e.preventDefault();
        this.submitted = true;
        if (this.createEditorForm.invalid) {
            return;
        }
        var password = e.target.elements[2].value;
        var confirmpassword = e.target.elements[3].value;
        if (password !== confirmpassword) {
            this.toast.error("Passwords should match!");
            return
        } else {
            this.userService.createEditor(e);
        }
    }
}