import { Component, OnInit, ViewChild } from "@angular/core";
import { ArticleService } from '../services/ArticleService';
import { NavbarService } from '../nav/navbar.service';
import { CategoryService } from '../services/CategoryServices';
import { ArticleDataService } from "../services/article-data.service";
import { Router } from "../../../node_modules/@angular/router";
import { FormBuilder, FormGroup, Validators } from "../../../node_modules/@angular/forms";
import { DropzoneComponent, DropzoneConfig, DropzoneConfigInterface, DropzoneDirective } from "../../../node_modules/ngx-dropzone-wrapper";
@Component({
    templateUrl: './edit-article.component.html'
})
export class EditArticleComponent implements OnInit {
    config: string = '';
    editArticleForm: FormGroup;
    submitted = false;
    name = 'ng2-ckeditor';
    ckeConfig: any;
    log: string = '';
    Categories: any[];
    article: any;
    articleContent: string;
    Images: any[];
    ImagesPair: Map<string, string> = new Map<string, string>();
    ImageMedia: boolean = false;
    VideoMedia: boolean = false;
    SelectedMedia: string = null;
    @ViewChild("myckeditor") ckeditor: any;
    @ViewChild('drpzone') drpzone: DropzoneDirective;
    constructor(private categoryService: CategoryService, private router: Router, public articleService: ArticleService, public nav: NavbarService, private articleDataService: ArticleDataService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.Images = [];
        this.article = this.articleDataService.articleData;
        if (this.articleDataService.articleData != null) {
            this.articleContent = this.article.text;
        } else {
            this.router.navigate(['/404']);
        }
        this.nav.show();
        this.categoryService.getCategories().subscribe((data) => {
            this.Categories = data;
        });
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };
        this.editArticleForm = this.formBuilder.group({
            title: ['', Validators.required],
            survey: ['', Validators.nullValidator],
            video: ['', Validators.nullValidator]
        });
    }
    ngAfterViewInit() {
        if (this.article.video != null && this.article.video != "") {
            this.SelectedMedia = "video";
        } else if (this.article.images != null && this.article.images.length > 0) {
            this.SelectedMedia = "image";
        }
        this.mediaSelection(this.SelectedMedia);
        this.article.images.forEach(element => {
            var mockFile = { name: element, size: 123456, fromInit: true };
            var _dropzone = this.drpzone.dropzone;
            _dropzone.emit("addedfile", mockFile);
            _dropzone.emit("thumbnail", mockFile, "https://tucher-app.de/api/images/" + element);
        });
    }
    get f() { return this.editArticleForm.controls; }
    updateArticle(e) {
        this.submitted = true;
        if (this.editArticleForm.invalid) {
            return;
        }
        document.getElementById('updateArticle').classList.add("fa-spinner")
        document.getElementById('updateArticle').classList.add("fa-spin")
        e.preventDefault();
        this.populateImagesList();
        if (this.article.category == null) {
            this.article.category = { "_id": e.target.elements[1].value };
            this.article.category._id = e.target.elements[1].value;
        }
        this.articleService.updateArticle(e, this.article, this.articleContent, this.Images, this.SelectedMedia).then((data) => {
            document.getElementById('updateArticle').classList.remove("fa-spinner")
            document.getElementById('updateArticle').classList.remove("fa-spin")
        });
    }
    onAddedFile(e) {
        var outer = this;
        var _base64: string;
        var _filePath: string = '';
        var _fileName: string = e.name;
        if (e.fromInit != null && e.fromInit == true) {
            _filePath = "https://tucher-app.de/api/images/" + e.name;

            var reader = new FileReader();
            var request = new XMLHttpRequest();
            request.open('GET', _filePath, true);
            request.responseType = 'blob';
            request.onload = function () {
                reader.readAsDataURL(request.response);
                reader.onload = function (e) {
                    _base64 = reader.result;
                    outer.ImagesPair.set(_fileName, _base64);
                };
            };
            request.send();
        } else {
            _fileName = e.name;
            var outer = this;
            var _base64: string;
            var _fileName: string = e.name;
            var reader = new FileReader();
            reader.onload = function (e) {
                _base64 = reader.result;
                outer.ImagesPair.set(_fileName, _base64);
            };
            reader.readAsDataURL(e);
        }
    }
    onRemovedFile(e) {
        var _fileName: string = e.name;
        this.ImagesPair.delete(_fileName);
    }
    populateImagesList() {
        this.Images = [];
        this.ImagesPair.forEach((value: string, key: string) => {
            this.Images.push(value);
        });
    }
    mediaSelection(value) {
        if (value === 'image') {
            this.ImageMedia = true;
            this.VideoMedia = false;
        } else if (value === 'video') {
            this.VideoMedia = true;
            this.ImageMedia = false;
        }
        this.SelectedMedia = value;
    }
    addImages(e) {
        e.preventDefault();
    }
    cancel() {
        this.router.navigate(['/articles/all']);
    }
}