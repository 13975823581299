import { Component, OnInit } from "@angular/core";
import { DepartmentService } from '../services/DepartmentServices';
import { NavbarService } from '../nav/navbar.service';
import { FormBuilder, FormGroup, Validators } from "../../../node_modules/@angular/forms";
@Component({
    templateUrl: './create-department.component.html'
})
export class CreateDepartmentComponent implements OnInit {
    createDepartmentForm: FormGroup;
    submitted = false;
    constructor(private departmentService: DepartmentService, public nav: NavbarService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.nav.show();
        this.createDepartmentForm = this.formBuilder.group({
            department: ['', Validators.required],
        });
    }
    get f() { return this.createDepartmentForm.controls; }
    addDepartment(e) {
        this.submitted = true;
        if (this.createDepartmentForm.invalid) {
            return;
        }
        e.preventDefault();
        document.getElementById('createDepartment').classList.add("fa-spinner")
        document.getElementById('createDepartment').classList.add("fa-spin")
        this.departmentService.addDepartment(e).then((data) => {
            document.getElementById('createDepartment').classList.remove("fa-spinner")
            document.getElementById('createDepartment').classList.remove("fa-spin")
        });
    }
}