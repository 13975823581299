import { PipeTransform, Pipe } from "../../../node_modules/@angular/core";
import { Article } from "../ViewModels/Article";

@Pipe({
    name:'articleFilter'
})
export class ArticleFilter implements PipeTransform{
    transform(articles: Article[], searchTerm: string): Article[]{
        if(!articles || !searchTerm){
            return articles;
        }
        return articles.filter(article =>
            {
                 const category = article.category.name.toLowerCase().includes(searchTerm)
                 const title = article.title.toLowerCase().includes(searchTerm.toLowerCase())
                 const creator = article.creator.name.toLowerCase().includes(searchTerm.toLowerCase())
                 return (category || title || creator);
            });
}
}
