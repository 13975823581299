import { Component, OnInit } from "@angular/core";
import { Observable } from 'node_modules/rxjs';
import { Router } from '@angular/router';
import { NavbarService } from '../nav/navbar.service';
import { AuthService } from '../services/AuthService';
import { Http, RequestOptions, Headers } from '@angular/http'
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
@Component({
    templateUrl: './forgotpassword.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    invalidEmail = false;
    validEmail = false;
    registerForm: FormGroup;
    submitted = false;
    constructor(private http: Http, private router: Router, public nav: NavbarService, private auth: AuthService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.nav.hide();
        this.registerForm = this.formBuilder.group({
            email: ['', Validators.required],
        });
    }
    get f() { return this.registerForm.controls; }
    sendResetLink(e): Observable<any> {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        e.preventDefault();
        var data: any;
        var email = e.target.elements[0].value;
        var body = {
            "email": email
        };
        var headers = new Headers({ 'Content-Type': 'application/json' });
        var options = new RequestOptions({ headers: headers });
        this.http.post("https://tucher-app.de/api/users/send-reset-link", body, options).subscribe(data => {
            if (JSON.parse(data["_body"]).status === 500) {
                this.invalidEmail = true;
            } else if (JSON.parse(data["_body"]).status === 200) {
                this.validEmail = true;
            }
        }, error => {
            console.log(JSON.stringify(error.json()));
        });
        return data;
    }
}