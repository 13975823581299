import { Component, OnInit } from "@angular/core";
import { UserService } from '../services/UserService';
import { NavbarService } from '../nav/navbar.service';
@Component({
    templateUrl: './add-user.component.html'
})
export class AddUserComponent implements OnInit {
    constructor(private userService: UserService, public nav: NavbarService) { }
    ngOnInit() {
        this.nav.show();
    }
    addUser(e) {
        e.preventDefault();
        this.userService.addUser(e);
    }
}