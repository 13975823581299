import { Component, Input } from "@angular/core";
import { CategoryService } from '../services/CategoryServices';
import { NavbarService } from '../nav/navbar.service';
@Component({
    templateUrl: './category-list.component.html'
})
export class CategoryListComponent {
    Category: any[];
    public popoverTitle: string = 'Löschen';
    public popoverMessage: string = 'Diesen Eintrag löschen?';
    public confirmText: string = 'Ja <i class="glyphicon glyphicon-ok"></i>';
    public cancelText: string = 'Nein <i class="glyphicon glyphicon-remove"></i>';
    loadingData: boolean;
    constructor(private categoryService: CategoryService, public nav: NavbarService) {
    }
    ngOnInit() {
        this.loadingData = true;
        this.nav.show();
        this.categoryService.getCategories().subscribe((data) => {
            this.loadingData = false;
            this.Category = data;
        });
    }
    deleteCategory(id: any) {
        document.getElementById('deleteCategory_' + id).classList.add("fa-spinner")
        document.getElementById('deleteCategory_' + id).classList.add("fa-spin")
        this.categoryService.deleteCategory(id).then((data) => {
            this.ngOnInit();
            document.getElementById('deleteCategory_' + id).classList.remove("fa-spinner")
            document.getElementById('deleteCategory_' + id).classList.remove("fa-spin")
        });
    }
}