import { Component, OnInit } from '@angular/core'
import { NavbarService } from './navbar.service';
import { AuthService } from '../services/AuthService';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'nav-bar',
    templateUrl: './nav-bar.component.html',
    styles: [`
    .nav.navbar-nav {font-size:15px;}
    #searchForm {margin-right:100px;}
    @media(max-wdth:1200px){ #searchForm{display:none}}
`]
})
export class NarBarComponent implements OnInit {
    constructor(public nav: NavbarService, private auth: AuthService, private route: ActivatedRoute) {
        this.ngOnInit();
    }
    ngOnInit() {
        this.auth.ngOnInit();
    }
}