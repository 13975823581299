import { Component, Input } from "@angular/core";
import { DepartmentService } from '../services/DepartmentServices';
import { NavbarService } from '../nav/navbar.service';
@Component({
    templateUrl: './department-list.component.html'
})
export class DepartmentListComponent {
    Department: any[]
    public popoverTitle: string = 'Löschen';
    public popoverMessage: string = 'Diesen Eintrag löschen?';
    public confirmText: string = 'Ja <i class="glyphicon glyphicon-ok"></i>';
    public cancelText: string = 'Nein <i class="glyphicon glyphicon-remove"></i>';
    loadingData: boolean
    constructor(private departmentService: DepartmentService, public nav: NavbarService) {
    }
    ngOnInit() {
        this.loadingData = true;
        this.nav.show();
        this.departmentService.getDepartments().subscribe((data) => {
            this.loadingData = false;
            this.Department = data;
        });
    }
    deleteDepartment(id: any) {
        document.getElementById('deleteDepartment_' + id).classList.add("fa-spinner")
        document.getElementById('deleteDepartment_' + id).classList.add("fa-spin")
        this.departmentService.deleteDepartment(id).then((data) => {
            this.ngOnInit();
            document.getElementById('deleteDepartment_' + id).classList.remove("fa-spinner")
            document.getElementById('deleteDepartment_' + id).classList.remove("fa-spin")
        });
    }
}