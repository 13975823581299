import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './AuthService';

@Injectable()
export class EditorRouteGuardActivator implements CanActivate{
constructor(private auth: AuthService, private router:Router){}
canActivate(){
  const isLoggedIn = !!this.auth.IsAuthenticated();
  const isEditor = !!this.auth.IsEditor();
  if(!isLoggedIn) {
      this.router.navigate(['/login']);
  }else if(!isEditor){
    this.router.navigate(['/404']);
  }
  return isLoggedIn;
}
}