import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http'
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UserService {
    constructor(private toastr: ToastrService, private http: Http, private router: Router) { }
    getUsers(): Observable<any[]> {
        var authToken = this.readKeyFromLocalStorage();
        var body = {
            "page": 1,
            "limit": 100
        };
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var options = new RequestOptions({ headers: headers });
        return this.http.post("https://tucher-app.de/api/users/all", body, options).map((response: Response) => {
            if (response.json().status === 500) {
                this.toastr.error("Ah Sorry, There is some error fetching users data. :(");
            } else if (response.json().status === 403) {
                this.toastr.error("Ah Sorry, You are not authorized to view users. :(");
            } else if (response.json().status === 200) {
                return <any[]>(response.json().data.docs);
            }
        });
    };
    addUser(e): Observable<any[]> {
        var data: any;
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var name = e.target.elements[0].value;
        var username = e.target.elements[1].value;
        var password = e.target.elements[2].value;
        var email = e.target.elements[4].value;
        var department = e.target.elements[5].value;
        var body = {
            "name": name,
            "email": email,
            "password": password,
            "department": department,
            "ecode": ""
        };
        var options = new RequestOptions({ headers: headers });
        this.http.post("https://tucher-app.de/api/users/register", body, options).subscribe(data => {
            if (data.json().status === 403) {
                this.toastr.error("Ah Sorry, You are not authorized to Add User :(");
            } else if (data.json().status === 500) {
                this.toastr.error("Ah Sorry, Registration failed :(");
            } else if (data.json().status === 404) {
                this.toastr.error("Ah Sorry, Resource not found! :(");
            } else if (data.json().status === 200) {
                this.router.navigate(['/users/all'])
            }
        }, error => {
            console.log(JSON.stringify(error.json()));
        });
        return data;
    };
    createEditor(e): Observable<any[]> {
        var data: any;
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var name = e.target.elements[0].value;
        var email = e.target.elements[1].value;
        var password = e.target.elements[3].value;
        var body = {
            "name": name,
            "email": email,
            "password": password
        };
        var options = new RequestOptions({ headers: headers });
        this.http.post("https://tucher-app.de/api/users/create-editor", body, options).subscribe(data => {
            if (data.json().status === 403) {
                this.toastr.error("Ah Sorry, You are not authorized to Add Editor :(");
            }
            else if (data.json().status === 500) {
                this.toastr.error("Ah Sorry, Creating Editor failed :(");
            } else if (data.json().status === 200) {
                this.router.navigate(['/users/all'])
            }
        }, error => {
            console.log(JSON.stringify(error.json()));
        });
        return data;
    };
    createAdmin(e): Observable<any[]> {
        var data: any;
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var name = e.target.elements[0].value;
        var email = e.target.elements[1].value;
        var password = e.target.elements[3].value;
        var body = {
            "name": name,
            "email": email,
            "password": password
        };
        var options = new RequestOptions({ headers: headers });
        this.http.post("https://tucher-app.de/api/users/create-admin", body, options).subscribe(data => {
            if (data.json().status === 403) {
                this.toastr.error("Ah Sorry, You are not authorized to Add Admin :(");
            }
            else if (data.json().status === 500) {
                this.toastr.error("Ah Sorry, Creating Admin failed :(");
            } else if (data.json().status === 200) {
                this.router.navigate(['/users/all'])
            }
        }, error => {
            console.log(JSON.stringify(error.json()));
        });
        return data;
    };
    banUser(id: string, isBan: Boolean) {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var body = {
            "userId": id,
            "isBan": isBan
        };
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve, reject) => {
            this.http.post("https://tucher-app.de/api/users/ban", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to ban/unban user :(");
                    resolve(false);
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Failed to ban/unban user :(");
                    resolve(false);
                } else if (data.json().status === 404) {
                    this.toastr.error("Ah Sorry, Resource not found! :(");
                    resolve(true);
                } else if (data.json().status === 200) {
                    resolve(true);
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };
    sendResetLink(email: string) {
        debugger;
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var body = {
            "email": email
        };
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve, reject) => {
            this.http.post("https://tucher-app.de/api/users/send-reset-link", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to reset user password :(");
                    resolve(false);
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Failed to send reset user email :(");
                    resolve(false);
                } else if (data.json().status === 404) {
                    this.toastr.error("Ah Sorry, Resource not found! :(");
                    resolve(false);
                } else if (data.json().status === 200) {
                    resolve(true);
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };
    deleteUser(id: string, isBan: Boolean) {
        var authToken = this.readKeyFromLocalStorage();
        var headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append("XAuthorization", authToken);
        var body = {
            "userId": id
        };
        var options = new RequestOptions({ headers: headers });
        return new Promise((resolve, reject) => {
            this.http.post("https://tucher-app.de/api/users/delete", body, options).subscribe(data => {
                if (data.json().status === 403) {
                    this.toastr.error("Ah Sorry, You are not authorized to delete user :(");
                    resolve(false);
                } else if (data.json().status === 500) {
                    this.toastr.error("Ah Sorry, Failed to delete user :(");
                    resolve(false);
                } else if (data.json().status === 404) {
                    this.toastr.error("Ah Sorry, Resource not found! :(");
                    resolve(false);
                } else if (data.json().status === 200) {
                    resolve(true);
                }
            }, error => {
                console.log(JSON.stringify(error.json()));
            });
        });
    };
    readKeyFromLocalStorage() {
        return JSON.parse(localStorage.getItem("xauth")).token;
    }
}