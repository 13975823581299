import { Component, Input } from "@angular/core";
import { ArticleService } from '../services/ArticleService';
import { NavbarService } from '../nav/navbar.service';
import { Router } from "@angular/router";
import { ArticleDataService } from "../services/article-data.service";
import { Article } from "../ViewModels/Article";

@Component({

    templateUrl: './articles-list.component.html'
})
export class ArticlesListComponent {
    searchTerm: string = '';
    Articles: Article[];
    public popoverTitle: string = 'Löschen';
    public popoverMessage: string = 'Diesen Eintrag löschen?';
    public confirmText: string = 'Ja <i class="glyphicon glyphicon-ok"></i>';
    public cancelText: string = 'Nein <i class="glyphicon glyphicon-remove"></i>';
    loadingData: boolean
    constructor(private articleService: ArticleService, public nav: NavbarService, private router: Router, private articleDataService: ArticleDataService) {
    }
    ngOnInit() {
        this.loadingData = true;
        this.nav.show();
        this.articleService.getArticles().subscribe((data) => {
            this.loadingData = false;
            this.Articles = data;
        });
        this.nav.ngOnInit();
    }
    putOnline(id: string) {
        document.getElementById('putonline_' + id).classList.add("fa-spinner")
        document.getElementById('putonline_' + id).classList.add("fa-spin")
        this.articleService.changeArticleStatus(id, "online").then((data) => {
            this.ngOnInit();
            document.getElementById('putonline_' + id).classList.remove("fa-spinner")
            document.getElementById('putonline_' + id).classList.remove("fa-spin")
        });
    }
    putOnDraft(id: string) {
        document.getElementById('putdraft_' + id).classList.add("fa-spinner")
        document.getElementById('putdraft_' + id).classList.add("fa-spin")
        this.articleService.changeArticleStatus(id, "draft").then((data) => {
            this.ngOnInit();
            document.getElementById('putdraft_' + id).classList.remove("fa-spinner")
            document.getElementById('putdraft_' + id).classList.remove("fa-spin")
        });
    }
    deleteArticle(id: string) {
        document.getElementById('deleteloader_' + id).classList.add("fa-spinner")
        document.getElementById('deleteloader_' + id).classList.add("fa-spin")
        this.articleService.deleteArticle(id).then((data) => {
            this.ngOnInit();
            document.getElementById('deleteloader_' + id).classList.remove("fa-spinner")
            document.getElementById('deleteloader_' + id).classList.remove("fa-spin")
        });
    }
    editArticle(article: Article) {
        this.articleDataService.articleData = article;
        this.router.navigate(['/article/edit', article.id]);
    }
}
