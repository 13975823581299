import { Component, OnInit } from "@angular/core";
import { NavbarService } from '../nav/navbar.service';
import { EmployeeCodesService } from "../services/EmployeeCodesServices";
import { FormBuilder, FormGroup, Validators } from "../../../node_modules/@angular/forms";
@Component({
    templateUrl: './create-code.component.html'
})
export class CreateCodeComponent implements OnInit {
    createEmployeeCodeForm: FormGroup;
    submitted = false;
    constructor(private codesService: EmployeeCodesService, public nav: NavbarService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.nav.show();
        this.createEmployeeCodeForm = this.formBuilder.group({
            code: ['', Validators.required],
        });
    }
    get f() { return this.createEmployeeCodeForm.controls; }
    addCode(e) {
        this.submitted = true;
        if (this.createEmployeeCodeForm.invalid) {
            return;
        }
        e.preventDefault();
        document.getElementById('createCode').classList.add("fa-spinner")
        document.getElementById('createCode').classList.add("fa-spin")
        this.codesService.addCode(e).then((data) => {
            document.getElementById('createCode').classList.remove("fa-spinner")
            document.getElementById('createCode').classList.remove("fa-spin")
        });
    }
}