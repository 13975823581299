import { Component, OnInit } from "@angular/core";
import { CategoryService } from '../services/CategoryServices';
import { NavbarService } from '../nav/navbar.service';
import { FormBuilder, FormGroup, Validators } from "../../../node_modules/@angular/forms";
@Component({
    templateUrl: './create-category.component.html'
})
export class CreateCategoryComponent implements OnInit {
    createCategoryForm: FormGroup;
    submitted = false;
    constructor(private categoryService: CategoryService, public nav: NavbarService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.nav.show();
        this.createCategoryForm = this.formBuilder.group({
            category: ['', Validators.required],
        });
    }
    get f() { return this.createCategoryForm.controls; }
    addCategory(e) {
        this.submitted = true;
        if (this.createCategoryForm.invalid) {
            return;
        }
        document.getElementById('createCategory').classList.add("fa-spinner")
        document.getElementById('createCategory').classList.add("fa-spin")
        e.preventDefault();
        this.categoryService.addCategory(e).then((data) => {
            document.getElementById('createCategory').classList.remove("fa-spinner")
            document.getElementById('createCategory').classList.remove("fa-spin")
        });
    }
}