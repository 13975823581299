import {Component } from '@angular/core'
@Component({
    template:`
    <h1 class="errorMessage">Seite nicht gefunden</h1>
    `,
    styles:[`
    .errorMessage{
        margin-top:150px;
        font-size:50px;
        text-align:center;
    }
    `]
})
export class Error404Component{
constructor(){
    
}
}