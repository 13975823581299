import { Component, Input } from "@angular/core";
import { UserService } from '../services/UserService';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../nav/navbar.service';
import { NarBarComponent } from "../nav/nav-bar.component";
@Component({
    templateUrl: './users-list.component.html'
})
export class UsersListComponent {
    User: any[]
    filter: string = '';
    public popoverTitle: string = 'Löschen';
    public popoverMessage: string = 'Diesen Eintrag löschen?';
    public popoverMessageForResetLink: string = 'Link senden?';
    public confirmText: string = 'Ja <i class="glyphicon glyphicon-ok"></i>';
    public cancelText: string = 'Nein <i class="glyphicon glyphicon-remove"></i>';
    loadingData: boolean
    constructor(private userService: UserService, public nav: NavbarService) {
    }
    ngOnInit() {
        this.loadingData = true;
        this.nav.show();
        this.nav.ngOnInit();
        this.userService.getUsers().subscribe((data) => {
            this.loadingData = false;
            this.User = data;
        });
    }
    deleteUser(id: string) {
        document.getElementById('delete_' + id).classList.add("fa-spinner")
        document.getElementById('delete_' + id).classList.add("fa-spin")
        this.userService.deleteUser(id, true).then((data) => {
            this.ngOnInit();
            document.getElementById('delete_' + id).classList.add("fa-spinner")
            document.getElementById('delete_' + id).classList.add("fa-spin")
        });
    }
    banUser(id: string) {
        document.getElementById('ban_' + id).classList.add("fa-spinner")
        document.getElementById('ban_' + id).classList.add("fa-spin")
        this.userService.banUser(id, true).then((data) => {
            this.ngOnInit();
            document.getElementById('ban_' + id).classList.add("fa-spinner")
            document.getElementById('ban_' + id).classList.add("fa-spin")
        });
    }
    unbanUser(id: string) {
        document.getElementById('unban_' + id).classList.add("fa-spinner")
        document.getElementById('unban_' + id).classList.add("fa-spin")
        this.userService.banUser(id, false).then((data) => {
            this.ngOnInit();
            document.getElementById('unban_' + id).classList.add("fa-spinner")
            document.getElementById('unban_' + id).classList.add("fa-spin")
        });
    }
    sendResetLink(id: string, email: string) {
        document.getElementById('resetlink_' + id).classList.add("fa-spinner")
        document.getElementById('resetlink_' + id).classList.add("fa-spin")
        this.userService.sendResetLink(email).then((data) => {
            document.getElementById('resetlink_' + id).classList.remove("fa-spinner")
            document.getElementById('resetlink_' + id).classList.remove("fa-spin")
        });
    }
}