import { Component, OnInit, ViewChild } from "@angular/core";
import { ArticleService } from '../services/ArticleService';
import { NavbarService } from '../nav/navbar.service';
import { CategoryService } from '../services/CategoryServices';
import { Router } from "../../../node_modules/@angular/router";
import { FormBuilder, FormGroup, Validators } from "../../../node_modules/@angular/forms";
import { DropzoneDirective, DropzoneComponent, DropzoneConfig, DropzoneConfigInterface, DropzoneModule, DropzoneEvents, DROPZONE_CONFIG, DROPZONE_GUARD } from "../../../node_modules/ngx-dropzone-wrapper";
@Component({
    templateUrl: './create-article.component.html'
})
export class CreateArticleComponent implements OnInit {
    config: string = '';
    createArticleForm: FormGroup;
    submitted = false;
    name = 'ng2-ckeditor';
    ckeConfig: any;
    Images: any[];
    ImagesPair: Map<string, string> = new Map<string, string>();
    articleContent: string;
    log: string = '';
    Categories: any[];
    @ViewChild("myckeditor") ckeditor: any;
    ImageMedia: boolean = false;
    VideoMedia: boolean = false;
    SelectedMedia: string = null;
    constructor(private router: Router, private categoryService: CategoryService, public articleService: ArticleService, public nav: NavbarService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.Images = [];
        this.nav.show();
        this.categoryService.getCategories().subscribe((data) => {
            this.Categories = data;
        });
        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true,
        };
        this.createArticleForm = this.formBuilder.group({
            title: ['', Validators.required]
        });
    }
    get f() { return this.createArticleForm.controls; }
    createArticle(e) {
        this.submitted = true;
        if (this.createArticleForm.invalid) {
            return;
        }
        this.populateImagesList();
        document.getElementById('createArticle').classList.add("fa-spinner");
        document.getElementById('createArticle').classList.add("fa-spin");
        e.preventDefault();
        this.articleService.createArticle(e, this.articleContent, this.Images, this.SelectedMedia).then((data) => {
            document.getElementById('createArticle').classList.remove("fa-spinner")
            document.getElementById('createArticle').classList.remove("fa-spin")
        });
    }
    onAddedFile(e) {
        var outer = this;
        var _base64: string;
        var _fileName: string = e.name;
        var reader = new FileReader();
        reader.onload = function (e) {
            _base64 = reader.result;
            outer.ImagesPair.set(_fileName, _base64);
        };
        reader.readAsDataURL(e);
    }
    onRemovedFile(e, dz) {
        var _fileName: string = e.name;
        this.ImagesPair.delete(_fileName);
    }
    populateImagesList() {
        this.Images = [];
        this.ImagesPair.forEach((value: string, key: string) => {
            this.Images.push(value);
        });
    }
    mediaSelection(value) {
        if (value === 'image') {
            this.ImageMedia = true;
            this.VideoMedia = false;
        } else if (value === 'video') {
            this.VideoMedia = true;
            this.ImageMedia = false;
        }
        this.SelectedMedia = value;
    }
    addImages(e) {
        e.preventDefault();
    }
    cancel() {
        this.router.navigate(['/articles/all']);
    }
}