import { Component, OnInit } from "@angular/core";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NavbarService } from '../nav/navbar.service';
import { AuthService } from '../services/AuthService';
import { Http, RequestOptions, Headers } from '@angular/http'
import { FormBuilder, FormGroup, Validators } from "../../../node_modules/@angular/forms";
@Component({
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    loginInvalid = false;
    registerForm: FormGroup;
    submitted = false;
    constructor(private http: Http, private router: Router, public nav: NavbarService, private auth: AuthService, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.nav.hide();
        this.registerForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', [Validators.required]]
        });
    }
    get f() { return this.registerForm.controls; }
    loginUser(e): Observable<any> {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        e.preventDefault();
        var data: any;
        var email = e.target.elements[0].value;
        var password = e.target.elements[1].value;
        var body = {
            "email": email,
            "password": password
        };
        var headers = new Headers({ 'Content-Type': 'application/json' });
        var options = new RequestOptions({ headers: headers });
        this.http.post("https://tucher-app.de/api/users/login", body, options).subscribe(data => {
            if (JSON.parse(data["_body"]).status === 500) {
                this.loginInvalid = true;
            } else if (JSON.parse(data["_body"]).status === 200) {
                let localStorageJsonData = JSON.parse(localStorage.getItem("xauth"));
                localStorageJsonData = data.json().data;
                localStorage.setItem("xauth", JSON.stringify(localStorageJsonData));
                if (this.auth.IsAdmin()) {
                    //  location.reload();
                    this.router.navigate(['/users/all']);
                } else {
                    // location.reload();
                    this.router.navigate(['/articles/all']);
                }
            }
        }, error => {
            console.log(JSON.stringify(error.json()));
        });
        return data;
    }
}